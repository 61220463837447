export const projects = [
  {
    title: "Discord Pinball Bot",
    subtitle: "Javascript",
    description:
      "A pinball score tracking bot made using Discord.js. Database managment through MongoDB and Mongoose. 24/7 Uptime through Heroku and multiple commands. Built to serve the needs of a local bar community and serves an active userbase.",
    image: "./discord_bot.jpeg",
    link: "https://github.com/brendanhorton/ScoreKeeperBot",
  },
  {
    title: "This Website!",
    subtitle: "ReactJS",
    description:
      "This portfolio website was built and designed from the ground up using ReactJS and TailwindCSS. Hosted using GitHub pages and Google Domains.",
    image: "./personalwebsite.png",
    link: "https://github.com/brendanhorton/brendanhorton.github.io",
  },
  {
    title: "Geeks Public House",
    subtitle: "Wordpress & CSS",
    description:
      "I built the website for a local business Geeks Public House. During this process I learned many new skills as I worked with wordpress and wordpress plugins for the first time.",
    image: "./geekswebsite.png",
    link: "https://geekspublichouse.com",
  },
  {
    title: "Discord Book Club Bot",
    subtitle: "Python",
    description:
      "A bot built to manage a bookclub on discord written in Discord.py. Multiple API calls and 24/7 uptime. Tracks user progress and provides contexual updates to group.",
    image: "./discord_bot2.png",
    link: "https://github.com/brendanhorton/BookClubDiscordBot",
  },
];
 
export const skills = [
  "JavaScript",
  "Python",
  "Git",
  "NodeJS",
  "Typescript",
  "ReactJS",
];